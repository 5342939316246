@import "antd/dist/antd.css";
@import "bulma/bulma.sass";
@import "./variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}

body::-webkit-scrollbar {
  width: 0;
}

.small-radius {
  border-radius: $small-radius !important;
}

.large-radius {
  border-radius: $large-radius !important;
}

.small-shadow {
  box-shadow: 15px 15px $small-shadow-spread $shadow-length $shadow-color !important;
}

.large-shadow {
  box-shadow: 0 15px $large-shadow-spread $shadow-length $shadow-color !important;
}

.divider-color {
  background-color: #ffffffbb;
}

.ant-card {
  border-radius: 7px !important;
  overflow: hidden;
}
